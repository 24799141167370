import { Link, useNavigate } from 'react-router-dom';
import styles from './home.module.scss';
import Button from '@/components/Button';
import HomeLayout from '@/layouts/HomeLayout';
import SEOHelmet from '@/components/SEOHelmet';
import EmailLink from '@/components/EmailLink';
const Home = () => {
  const navigate = useNavigate();
  return (
    <HomeLayout showNav={false}>
      <SEOHelmet
        title="GANNET"
        description="AI for humanitarians, by humanitarians. GANNET is a real-time, all-in-one Generative AI platform for humanitarian collaboration and quick decision-making."
        image={`${window.location.origin}/gannet-green.png`}
      />
      <div className={styles['home-content']}>
        <img
          className={styles['logo']}
          src="/gannet-green.png"
          height="150"
          alt="Gannet green logo"
        />
        <span>AI for humanitarians, by humanitarians</span>
        <p>
          <strong>
            GANNET is a real-time, all-in-one Generative AI platform for
            humanitarian collaboration and quick decision-making.
          </strong>{' '}
          It is a humanitarian's trusted sidekick combining near real-time AI,
          analytics, visualisation, monitoring, and alerts. GANNET provides them
          with the latest knowledge from reliable sources to tackle the
          ever-changing challenges of humanitarian crises head-on.
        </p>

        <p>
          Interested in funding or partnering with us? Contact us at{' '}
          <EmailLink />
          <Button
            variation="alt"
            className={styles['early-access-btn']}
            onClick={() => navigate('sign-up')}
          >
            SIGN UP
          </Button>
          or <Link to="/sign-in">sign in</Link> if you already have an account.
        </p>

        <div className={styles['footer']}>
          <p className={styles['privacy']}>
            Learn about our privacy policy <Link to="/privacy">here</Link>.
          </p>
          <div className={styles['brand']}>
            A tool by
            <a href="https://datafriendlyspace.org" target="_blank">
              <img src="./dfs-logo.svg" alt="Data Friendly Space" />
            </a>
          </div>
        </div>
      </div>
    </HomeLayout>
  );
};

export default Home;
